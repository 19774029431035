
.pageTop {
  a {
    background-color: $baseBgColor1;
    //background: -webkit-linear-gradient(#42a1ec,#0070c9);
    //background: linear-gradient(#42a1ec,#0070c9);
    //border-color: #07c;
    margin: 0 0 0 auto;
    text-decoration: none;
    color: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    display: block;
    padding: 13px 0;
    @include fontsize(9);
    i {
      display: block;
      @include fontsize(20);
    }
  }
}
