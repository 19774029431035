
.modal-content {
  background-color: transparent;
  border: none;
  @include border-radius(0);
  button {
    &.modalHeadClose {
      text-align: right;
      color: #FFF;
      text-shadow: none;
      @include fontsize(50);
      font-weight: normal;
    }

    &.modalFooterClose {
      display: block;
      background-color: #FFF;
      float: none;
      width: 200px;
      margin: 30px auto 20px auto;
      text-shadow: none;
      opacity: 1;
      padding: 20px 0;
      font-weight: normal;
      @include fontsize(15);
    }
  }
}

.interviewModal {
  @include border-radius(0);
  background-color: #0f353d;
  padding: 30px;

  h2 {
    color: #9fdbe9;
    margin: 30px 0 20px;
    @include fontsize(12);
    @media #{$sm} {
    }
    @media #{$md} {
      @include fontsize(15);
    }
    @media #{$lg} {
      @include fontsize(15);
    }
    @media #{$xl} {
      @include fontsize(16);
    }

  }
  p {
    color: #FFF;
    line-height: 200%;
    @include fontsize(10);
    @media #{$sm} {
    }
    @media #{$md} {
      @include fontsize(13);
    }
    @media #{$lg} {
      @include fontsize(13);
    }
    @media #{$xl} {
      @include fontsize(13);
    }
  }
}


.customMenuModal {
  background-color: #0f353d;
  color: #FFF;
  .modal-dialog {
    margin: 0;
  }
  .modal-header {
    background-color: #0a252a;
    border-bottom: .5px solid #3b6262;
    h5 {
      padding-top: 8px;
      padding-left: 10px;
      padding-bottom: 8px;
      @include fontsize(14);
    }
    .modalCloseBtn {
      color: #FFF;
      font-weight: normal;
      text-shadow: none;
      @include fontsize(30);
    }
  }
  .modal-body {
    padding: 0;
  }
  ul {
    &.modalMenuMain {
      margin-bottom: 30px;
      li {
        a {
          padding: 10px 30px 10px 30px;
          display: block;
          color: #FFF;
          border-bottom: .5px solid #3b6262;
          &.children {
            padding: 10px 0 10px 40px;
            background-color: #3b6262;
            border-bottom: .5px solid #6bb2b2;
          }

          i {
            float: right;
          }
        }
      }
    }
    &.modalMenuCompany {
      @include fontsize(0);
      li {
        padding: 0 10px;
        width: 50%;
        display: inline-block;

        a {
          display: block;
          padding: 15px 5px;
          background-color: #21c8dd;
          color: #FFF;
          @include fontsize(11);
          text-align: center;
        }
      }
    }
  }
}