
section {
  &.eyeCatch {
    height: 200px;
    overflow: hidden;

    @media #{$sm} {
      height: 250px;
      overflow: hidden;
    }
    @media #{$md} {
      height: 400px;
      overflow: hidden;
    }
    @media #{$lg} {
      height: 500px;
      overflow: hidden;
    }
    @media #{$xl} {
      height: 600px;
      overflow: hidden;
    }

  }

  &.contentEyeCatch {
    height: 100px;
    overflow: hidden;

    @media #{$sm} {
      height: 150px;
      overflow: hidden;
    }
    @media #{$md} {
      height: 300px;
      overflow: hidden;
    }
    @media #{$lg} {
      height: 300px;
      overflow: hidden;
    }
    @media #{$xl} {
      height: 300px;
      overflow: hidden;
    }
    img {
      width: 100%;
    }
  }


}