/*****************************************************
  right-menu
*****************************************************/
.right-menu {
  position: fixed;
  right: 3.5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  li {
    margin-bottom: 5vh;
  }

  a {
    display: block;
    line-height: 0;
    position: relative;
    &:before {
      background-color: #f7931e;
      border-radius: 50%;
      content: "";
      display: block;
      height: 8px;
      width: 8px;
    }
  }
}

@media screen and (min-width: 769px) {
  .right-menu a.active:before,
  .right-menu.pc-right a:hover:before {
    background-color: #f7931e;
  }
  .right-menu a.active:after,
  .right-menu.pc-right a:hover:after{
    border: 2px solid transparent;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 2px #f7931e;
    -moz-box-shadow: 0 0 0 2px #f7931e;
    box-shadow: 0 0 0 2px #f7931e;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    height: 14px;
    width: 14px;
  }
}

@media screen and (max-width: 768px) {
  .right-menu {
    display: none !important;
  }
}