

section {

  &.newsList {

    background-color: #FFF;
    color: #333;
    //@media #{$sm} {
    //}
    @media #{$md} {
      background-color: #293336;
      color: #afafaf;
    }
    @media #{$lg} {
      background-color: #293336;
      color: #afafaf;
    }
    @media #{$xl} {
      background-color: #293336;
      color: #afafaf;
    }

    h2 {
      @include fontsize(20);
      text-align: center;
      @media #{$sm} {
        text-align: center;
      }
      @media #{$md} {
        @include fontsize(20);
        margin: 0;
      }
      @media #{$lg} {
        @include fontsize(24);
        margin: 0;
      }
      @media #{$xl} {
        @include fontsize(24);
        margin: 0;
      }

    }


    span {
      &.newsCategory {
        background-color: $btnColor;
        display: inline-block;
        padding: 4px 20px 3px 20px;
        color: #FFF;
        margin-right: 10px;
        @include fontsize(10);
        @media #{$sm} {
          padding: 4px 20px 3px 20px;
          margin-right: 10px;
          @include fontsize(10);
        }
        @media #{$md} {
          padding: 6px 20px 5px 20px;
          @include fontsize(13);
        }
        @media #{$lg} {
          padding: 6px 20px 5px 20px;
          @include fontsize(13);
        }
        @media #{$xl} {
          padding: 6px 20px 5px 20px;
          @include fontsize(13);
        }
      }
      &.newsTitle {
        display: inline-block;
        margin-right: 10px;
        @include fontsize(10);
        @media #{$sm} {
          margin-right: 10px;
          @include fontsize(10);
        }
        @media #{$md} {
          @include fontsize(13);
        }
        @media #{$lg} {
          @include fontsize(13);
        }
        @media #{$xl} {
          @include fontsize(13);
        }
      }
    }
    time {
      display: inline-block;
      margin-right: 10px;
      @include fontsize(10);
      @media #{$sm} {
        margin-right: 10px;
        @include fontsize(10);
      }
      @media #{$md} {
        @include fontsize(13);
      }
      @media #{$lg} {
        @include fontsize(13);
      }
      @media #{$xl} {
        @include fontsize(13);
      }
    }

    p {
      &.newsText {
        padding: 15px 0;
        @include fontsize(10);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(13);
        }
        @media #{$lg} {
          @include fontsize(13);
        }
        @media #{$xl} {
          @include fontsize(13);
        }
      }
    }
    .newsAccordion {
      display: block;
      @media #{$sm} {
        display: block;
      }
      @media #{$md} {
        display: none;
      }
      @media #{$lg} {
        display: none;
      }
      @media #{$xl} {
        display: none;
      }
    }

    .newsLinkBox {
      margin-top: -5px;
      text-align: right;
      img {
        max-width: 150px;
      }
    }

  }




  &.topCompany {
    .companyBox {
      margin-bottom: 2.5rem;

      text-align: center;
      @media #{$sm} {
        text-align: center;
      }
      @media #{$md} {
        text-align: left;
      }
      @media #{$lg} {
        text-align: left;
      }
      @media #{$xl} {
        text-align: left;
      }


    }
    h3 {
      &.companyTitle {
        @include fontsize(22);
        font-weight: bold;
        margin: 0 0 10px;

      }
    }
    p {
      margin: 5px 0 8px;
      @include fontsize(11);
      @media #{$sm} {
        @include fontsize(11);
      }
      @media #{$md} {
        @include fontsize(14);
      }
      @media #{$lg} {
        @include fontsize(14);
      }
      @media #{$xl} {
        @include fontsize(14);
      }
    }
  }



  &.bgGray {
    background-color: #f9f9f9;
  }


  /**
  各背景指定
   */
  &.topStart {
    background-image: url("../../images/top_start.jpg");
    background-repeat: no-repeat;

    background-size: 100%;
    background-position: top center;
    @media #{$sm} {
    }
    @media #{$md} {
      background-size: auto;
      background-position: right center;
    }
    @media #{$lg} {
      background-size: auto;
      background-position: right center;
    }
    @media #{$xl} {
      background-size: auto;
      background-position: right center;
    }
  }
  &.topContribution {
    background-image: url("../../images/top_contribution.jpg");
    background-repeat: no-repeat;

    background-size: 100%;
    background-position: top center;
    @media #{$sm} {
    }
    @media #{$md} {
      background-size: auto;
      background-position: left center;
    }
    @media #{$lg} {
      background-size: auto;
      background-position: left center;
    }
    @media #{$xl} {
      background-size: auto;
      background-position: left center;
    }

    .contentBox {
      margin: 100px 15px 50px 15px;
      @media #{$sm} {
      }
      @media #{$md} {
        margin: 50px 0 50px auto;
      }
      @media #{$lg} {
        margin: 50px 0 50px auto;
      }
      @media #{$xl} {
        margin: 50px 0 50px auto;
      }

    }
  }
  &.topPhilosophy {
    background-image: url("../../images/top_philosophy.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
    @media #{$sm} {
    }
    @media #{$md} {
      background-size: auto;
      background-position: right center;
    }
    @media #{$lg} {
      background-size: auto;
      background-position: right center;
    }
    @media #{$xl} {
      background-size: auto;
      background-position: right center;
    }
  }


  .contentBox {
    background-color: #FFF;
    padding: 20px;
    margin: 100px 15px 50px 15px;
    @media #{$sm} {
      padding: 20px;
    }
    @media #{$md} {
      margin: 50px 0 50px 0;
      padding: 30px;
      width: 400px;
    }
    @media #{$lg} {
      margin: 50px 0 50px 0;
      padding: 30px;
      width: 400px;
    }
    @media #{$xl} {
      margin: 50px 0 50px 0;
      padding: 30px;
      width: 400px;
    }

    h2 {
      position: relative;
      color: #999999;
      text-align: center;

      @include fontsize(22);
      margin: 0 auto 20px;
      @media #{$sm} {
        @include fontsize(22);
        margin: 0 auto 20px;
      }
      @media #{$md} {
        @include fontsize(25);
        margin: 0 auto 30px;
      }
      @media #{$lg} {
        @include fontsize(25);
        margin: 0 auto 30px;
      }
      @media #{$xl} {
        @include fontsize(25);
        margin: 0 auto 30px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -5px; /*下線の上下位置調整*/
        display: inline-block;
        width: 75px; /*下線の幅*/
        height: 2px; /*下線の太さ*/
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%); /*位置調整*/
        background-color: #999999; /*下線の色*/
      }
    }

    p {
      @include fontsize(11);
      @media #{$sm} {
        @include fontsize(11);
      }
      @media #{$md} {
        @include fontsize(13);
      }
      @media #{$lg} {
        @include fontsize(13);
      }
      @media #{$xl} {
        @include fontsize(13);
      }
    }
  }





  &.topPhotoBlock {
    .topPhotoLeft {
      width: 100%;
      @media #{$sm} {
        width: 100%;
      }
      @media #{$md} {
        width: 50%;
        float: left;
      }
      @media #{$lg} {
        width: 50%;
        float: left;
      }
      @media #{$xl} {
        width: 50%;
        float: left;
      }
    }
    .topPhotoRight {
      width: 100%;
      @media #{$sm} {
        width: 100%;
      }
      @media #{$md} {
        width: 50%;
        float: right;
      }
      @media #{$lg} {
        width: 50%;
        float: right;
      }
      @media #{$xl} {
        width: 50%;
        float: right;
      }
    }


    .photoListBox {
      background-image: url("../../images/photo_list.jpg");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 250px;
      @media #{$sm} {
        height: 150px;
      }
      @media #{$md} {
        height: 500px;
      }
      @media #{$lg} {
        height: 500px;
      }
      @media #{$xl} {
        height: 500px;
      }
      position: relative;
      .photoListBtnBox {
        position: absolute;
        bottom: 20px;
        right: 20px;
        @media #{$sm} {
        }
        @media #{$md} {
          bottom: 30px;
          right: 30px;
        }
        @media #{$lg} {
          bottom: 30px;
          right: 30px;
        }
        @media #{$xl} {
          bottom: 30px;
          right: 30px;
        }
        img {
          max-width: 150px;
          @media #{$sm} {
          }
          @media #{$md} {
            max-width: 172px;
          }
          @media #{$lg} {
            max-width: 172px;
          }
          @media #{$xl} {
            max-width: 172px;
          }
        }
      }
    }
    .cultureBox {
      background-image: url("../../images/culture.jpg");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 150px;
      width: 50%;
      float: left;
      @media #{$sm} {
        height: 150px;
        width: 50%;
      }
      @media #{$md} {
        height: 250px;
        width: 100%;
      }
      @media #{$lg} {
        height: 250px;
        width: 100%;
      }
      @media #{$xl} {
        height: 250px;
        width: 100%;
      }
    }
    .aboutUsBox {
      background-image: url("../../images/about_us.jpg");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      height: 150px;
      width: 50%;
      float: right;
      @media #{$sm} {
        height: 150px;
        width: 50%;
      }
      @media #{$md} {
        height: 250px;
        width: 100%;
      }
      @media #{$lg} {
        height: 250px;
        width: 100%;
      }
      @media #{$xl} {
        height: 250px;
        width: 100%;
      }
    }
    .cultureBox, .aboutUsBox {
      position: relative;
      .topPhotoRightBtn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        @media #{$sm} {
        }
        @media #{$md} {
          bottom: 30px;
          right: 30px;
        }
        @media #{$lg} {
          bottom: 30px;
          right: 30px;
        }
        @media #{$xl} {
          bottom: 30px;
          right: 30px;
        }
        img {
          max-width: 30px;
          @media #{$sm} {
          }
          @media #{$md} {
            max-width: 50px;
          }
          @media #{$lg} {
            max-width: 50px;
          }
          @media #{$xl} {
            max-width: 50px;
          }
        }
      }
    }
    .jobListBox {
      background-image: url("../../images/job_list.jpg");
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      text-align: center;
      height: 140px;
      @media #{$sm} {
      }
      @media #{$md} {
        height: 300px;
      }
      @media #{$lg} {
        height: 300px;
      }
      @media #{$xl} {
        height: 300px;
      }
      h3 {
        margin-top: 50px;
        @include fontsize(25);
      }
      .topJobListBtn {
        margin-top: 25px;
        @media #{$sm} {
        }
        @media #{$md} {
          margin-top: 40px;
        }
        @media #{$lg} {
          margin-top: 40px;
        }
        @media #{$xl} {
          margin-top: 40px;
        }
        img {
          max-width: 250px;
          @media #{$sm} {
          }
          @media #{$md} {
            max-width: 300px;
          }
          @media #{$lg} {
            max-width: 300px;
          }
          @media #{$xl} {
            max-width: 300px;
          }
        }
      }
    }

    .overBlue {
      background-color: rgba(59, 65, 114, 0.6);
      height: 100%;
      padding: 20px;
    }
    .overGreen {
      background-color: rgba(7, 57, 25, 0.4);
      height: 100%;
      padding: 20px;
    }


    h3 {
      &.underLine {
        font-weight: bold;
        position: relative;
        display: inline-block;
        @include fontsize(20);
        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px; /*下線の上下位置調整*/
          display: inline-block;
          width: 75px; /*下線の幅*/
          height: 2px; /*下線の太さ*/
          //-moz-transform: translateX(-50%);
          //-webkit-transform: translateX(-50%);
          //-ms-transform: translateX(-50%);
          //transform: translate(-50%); /*位置調整*/
          background-color: #f7931e; /*下線の色*/
        }
      }
      &.white {
        color: #FFF;
      }
    }


  }


  &.sns {
    .snsLeft {
      padding: 20px 0;
      width: 40%;
      float: left;
      display: none;
      @media #{$sm} {
        display: none;
      }
      @media #{$md} {
        display: block;
      }
      @media #{$lg} {
        display: block;
      }
      @media #{$xl} {
        display: block;
      }
      p {
        text-align: center;
        padding: 0;
        margin: 0;
        @include fontsize(10);
        @media #{$sm} {
          @include fontsize(10);
        }
        @media #{$md} {
          @include fontsize(12);
        }
        @media #{$lg} {
          @include fontsize(14);
        }
        @media #{$xl} {
          @include fontsize(17);
        }
      }
    }
    .snsRight {
      @media #{$sm} {
      }
      @media #{$md} {
        width: 60%;
        float: right;
      }
      @media #{$lg} {
        width: 60%;
        float: right;
      }
      @media #{$xl} {
        width: 60%;
        float: right;
      }
    }
  }


}





h2 {
  &.employeeTitle {
    font-weight: bold;
  }
}
p {
  &.employeeRead {
    @include fontsize(12);
    @media #{$sm} {
      @include fontsize(12);
    }
    @media #{$md} {
      @include fontsize(13);
    }
    @media #{$lg} {
      @include fontsize(14);
    }
    @media #{$xl} {
      @include fontsize(15);
    }
  }
}
.card {
  &.cardEmployee {
    border: none;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    @media #{$sm} {
      width: 90%;
    }
    @media #{$md} {
      width: 100%;
    }
    @media #{$lg} {
      width: 100%;
    }
    @media #{$xl} {
      width: 100%;
    }
  }
}
h4 {
  &.cardEmployeeName {
    background-color: $btnColor;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    color: #FFF;
    @include fontsize(16);
  }
}

p {
  &.cardProfile {
    color: #797979;
    @include fontsize(15);
    margin: 10px 0;
  }
  &.cardProfileText {
    @include fontsize(12);
  }
}





