

header {

  nav {
    .nav-item {
      a {
        &.nav-link {
          &.navUnderline {
            @include fontsize(13);
            font-weight: bold;
            color: #000;
            border-bottom: 2px solid #DDD;

            @media #{$sm} {
            }
            @media #{$md} {
            }
            @media #{$lg} {
              margin: 10px 10px 0;
              @include fontsize(13);
            }
            @media #{$xl} {
              //padding: 0 30px;
              margin: 10px 15px 0;
              @include fontsize(13);
            }


          }
        }
      }
    }
  }

  img {
    &.headerLogo {
      max-width: 200px;
      @media #{$sm} {
      }
      @media #{$md} {
        max-width: 300px;
      }
      @media #{$lg} {
        max-width: 300px;
      }
      @media #{$xl} {
        max-width: 300px;
        margin-top: 20px;
      }
    }
  }

  .headEntryBtn {
    background-color: $btnColor;
    color: #FFF !important;
    @include fontsize(13);
    padding-left: 10px !important;
    padding-right: 10px !important;
    @media #{$sm} {
    }
    @media #{$md} {
    }
    @media #{$lg} {
      margin-top: 10px;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
    @media #{$xl} {
      margin-top: 10px;
      margin-left: 40px;
      padding-left: 30px !important;
      padding-right: 30px !important;
    }
  }
}