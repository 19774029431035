
footer {
  background-color: #293336;

  .footerLogo {
    width: 80%;
    margin: 20px auto 0;
    @media #{$sm} {
    }
    @media #{$md} {
      width: 100%;
      margin: 0;
    }
    @media #{$lg} {
      width: 100%;
      margin: 0;
    }
    @media #{$xl} {
      width: 100%;
      margin: 0;
    }
  }

  ul {
    font-size: 0;

    margin-top: 20px;
    @media #{$sm} {
      margin-top: 20px;
    }
    @media #{$md} {
      margin-top: 0;
    }
    @media #{$lg} {
      margin-top: 0;
    }
    @media #{$xl} {
      margin-top: 0;
    }

    &.footerNav {
      li {
        display: inline-block;
        width: 33.333333%;
        padding: 3px 0;
        margin: 0;
        @media #{$sm} {
          width: 33.333333%;
        }
        @media #{$md} {
          width: 50%;
        }
        @media #{$lg} {
          width: 50%;
        }
        @media #{$xl} {
          width: 50%;
        }

        a {
          color: #979797;
          @include fontsize(9);
          @media #{$sm} {
            @include fontsize(9);
          }
          @media #{$md} {
            @include fontsize(9);
          }
          @media #{$lg} {
            @include fontsize(10);
          }
          @media #{$xl} {
            @include fontsize(10);
          }
        }
      }
    }
  }

  .footerSns {
    display: none;
    @media #{$sm} {
      display: none;
    }
    @media #{$md} {
      display: block;
    }
    @media #{$lg} {
      display: block;
    }
    @media #{$xl} {
      display: block;
    }
    a {
      color: #FFF;
      display: inline-block;
      padding: 0 5px;
    }
  }


  p {
    &.copyRight {
      color: #979797;
      margin-bottom: 0;
      @include fontsize(9);
    }
  }
}