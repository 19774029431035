a {
  &.btnCompanyDetail {
    background-color: $btnColor;
    border: 1px solid $btnColor;
    padding-left: 20px;
    padding-right: 20px;


    @media #{$sm} {
    }
    @media #{$md} {
    }
    @media #{$lg} {
    }
    @media #{$xl} {
    }

  }




}



.snsBtnBlock {
  font-size: 0;
  a {
    &.btnSns {
      margin: 0;
      color: #FFF;
      width: 33.333333%;
      display: inline-block;
      text-align: center;
      padding: 20px 0;
      @include fontsize(17);
      &:hover {
        text-decoration: none;
      }
    }
    &.btnFacebook {
      background-color: #3b5998;
    }
    &.btnTwitter {
      background-color: #43c6f2;
    }
    &.btnInstagram {
      background: linear-gradient(90deg, #f68435, #eb278e);
    }

    span {
      &.snsTitlePc {
        display: none;
        @media #{$sm} {
          display: none;
        }
        @media #{$md} {
          display: inline;
        }
        @media #{$lg} {
          display: inline;
        }
        @media #{$xl} {
          display: inline;
        }
      }
      &.snsTitleSp {
        display: block;
        @media #{$sm} {
          display: block;
        }
        @media #{$md} {
          display: none;
        }
        @media #{$lg} {
          display: none;
        }
        @media #{$xl} {
          display: none;
        }
      }
    }

  }
}



a {
  &.btnJobEntry {
    display: block;
    background-color: #145865;
    color: #FFF;
    text-align: center;
    padding: 10px 10px;
    margin: 0 auto;
    width: 100%;
    @media #{$sm} {
    }
    @media #{$md} {
      width: 60%;
    }
    @media #{$lg} {
      width: 60%;
    }
    @media #{$xl} {
      width: 60%;
    }
    &:hover {
      text-decoration: none;
    }
  }
}





button {
  &.newsClosePc {
    display: block;
    background-color: #FFF;
    float: none;
    width: 200px;
    margin: 30px auto 20px auto;
    text-shadow: none;
    opacity: 1;
    padding: 20px 0;
    font-weight: normal;
    @include fontsize(15);
  }


  //エントリーフォーム
  //確認ボタン
  &.confirmBtn {
    display: block;
    color: #FFF;
    background-color: #145865;
    margin: 20px auto;
    border: none;
    padding: 15px;

    width: 100%;
    @media #{$md} {
      width: 60%;
    }
    @media #{$lg} {
      width: 60%;
    }
    @media #{$xl} {
      width: 60%;
    }
  }




}
