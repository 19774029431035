
ol {
  &.breadcrumb {
    padding: 0;
    border-radius: unset;
    background-color: #e7e7e7;
    li {
      &.breadcrumbHome {
        a {
          display: block;
          width: 150px;
          background-color: #145865;
          color: #21c8dd;
          text-align: center;
          padding: 6px 0 5px;
        }
      }
    }

  }
}


.breadcrumb-item+.breadcrumb-item::before {
  content: "";
}
.breadcrumb-item+.breadcrumb-item {
  padding: 6px 0 5px 10px;
  a {
    color: #145865;
    text-decoration: underline;
  }
}
.breadcrumb-item.active {
  color: #000;
}


