/*
Theme Name: waterServerGuide
*/

$xs:  "only screen and (min-width:375px) and (max-width:575px)";
$sm:  "only screen and (min-width:576px) and (max-width:767px)";
$md:  "only screen and (min-width:768px) and (max-width:991px)";
$lg: "only screen and (min-width:992px) and (max-width:1199px)";
$xl:  "only screen and (min-width:1200px)";


$btnColor: #21c8dd;
$baseBgColor1: #293336;

body{
  font-family: Poppins, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  position: relative;
  right: 0;
  overflow-x: hidden;
  background-color: #FFF !important;
}

a:link,
a:visited,
a:active,
a:hover { /* FireFox リンク選択時の点線を消す */
  outline: none;
}
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}

/* box-sizing設定 IE7非対応 */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}




// mixin 読み込み　よく使う関数なんかを格納
@import "mixin";
@import "pagetop";
@import "footer";
@import "menu";
@import "header";
@import "icatch";
@import "indexList";
@import "front-page";
@import "contents";
@import "btn";
@import "right-menu";
@import "bread-crumb";
@import "modal";

//read slick
@import "slick/slick";
@import "slick/slick-theme";


.wrap {
  display:none;
}

//.container {
//  width: auto;
//  max-width: 1170px;
//  padding: 0 15px;
//}

