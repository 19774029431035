@charset "UTF-8";
/*
Theme Name: waterServerGuide
*/
body {
  font-family: Poppins, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, sans-serif;
  position: relative;
  right: 0;
  overflow-x: hidden;
  background-color: #FFF !important; }

a:link,
a:visited,
a:active,
a:hover {
  /* FireFox リンク選択時の点線を消す */
  outline: none; }

ul, li {
  list-style: none;
  margin: 0;
  padding: 0; }

/* box-sizing設定 IE7非対応 */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

/*ベースフォントサイズ*/
.pageTop a {
  background-color: #293336;
  margin: 0 0 0 auto;
  text-decoration: none;
  color: #fff;
  width: 60px;
  height: 60px;
  text-align: center;
  display: block;
  padding: 13px 0;
  font-size: 9px;
  font-size: 0.5625rem; }
  .pageTop a i {
    display: block;
    font-size: 20px;
    font-size: 1.25rem; }

footer {
  background-color: #293336; }
  footer .footerLogo {
    width: 80%;
    margin: 20px auto 0; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      footer .footerLogo {
        width: 100%;
        margin: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      footer .footerLogo {
        width: 100%;
        margin: 0; } }
    @media only screen and (min-width: 1200px) {
      footer .footerLogo {
        width: 100%;
        margin: 0; } }
  footer ul {
    font-size: 0;
    margin-top: 20px; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      footer ul {
        margin-top: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      footer ul {
        margin-top: 0; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      footer ul {
        margin-top: 0; } }
    @media only screen and (min-width: 1200px) {
      footer ul {
        margin-top: 0; } }
    footer ul.footerNav li {
      display: inline-block;
      width: 33.333333%;
      padding: 3px 0;
      margin: 0; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        footer ul.footerNav li {
          width: 33.333333%; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        footer ul.footerNav li {
          width: 50%; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        footer ul.footerNav li {
          width: 50%; } }
      @media only screen and (min-width: 1200px) {
        footer ul.footerNav li {
          width: 50%; } }
      footer ul.footerNav li a {
        color: #979797;
        font-size: 9px;
        font-size: 0.5625rem; }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
          footer ul.footerNav li a {
            font-size: 9px;
            font-size: 0.5625rem; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          footer ul.footerNav li a {
            font-size: 9px;
            font-size: 0.5625rem; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          footer ul.footerNav li a {
            font-size: 10px;
            font-size: 0.625rem; } }
        @media only screen and (min-width: 1200px) {
          footer ul.footerNav li a {
            font-size: 10px;
            font-size: 0.625rem; } }
  footer .footerSns {
    display: none; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      footer .footerSns {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      footer .footerSns {
        display: block; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      footer .footerSns {
        display: block; } }
    @media only screen and (min-width: 1200px) {
      footer .footerSns {
        display: block; } }
    footer .footerSns a {
      color: #FFF;
      display: inline-block;
      padding: 0 5px; }
  footer p.copyRight {
    color: #979797;
    margin-bottom: 0;
    font-size: 9px;
    font-size: 0.5625rem; }

header nav .nav-item a.nav-link.navUnderline {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: bold;
  color: #000;
  border-bottom: 2px solid #DDD; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    header nav .nav-item a.nav-link.navUnderline {
      margin: 10px 10px 0;
      font-size: 13px;
      font-size: 0.8125rem; } }
  @media only screen and (min-width: 1200px) {
    header nav .nav-item a.nav-link.navUnderline {
      margin: 10px 15px 0;
      font-size: 13px;
      font-size: 0.8125rem; } }

header img.headerLogo {
  max-width: 200px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    header img.headerLogo {
      max-width: 300px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    header img.headerLogo {
      max-width: 300px; } }
  @media only screen and (min-width: 1200px) {
    header img.headerLogo {
      max-width: 300px;
      margin-top: 20px; } }

header .headEntryBtn {
  background-color: #21c8dd;
  color: #FFF !important;
  font-size: 13px;
  font-size: 0.8125rem;
  padding-left: 10px !important;
  padding-right: 10px !important; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    header .headEntryBtn {
      margin-top: 10px;
      padding-left: 30px !important;
      padding-right: 30px !important; } }
  @media only screen and (min-width: 1200px) {
    header .headEntryBtn {
      margin-top: 10px;
      margin-left: 40px;
      padding-left: 30px !important;
      padding-right: 30px !important; } }

section.eyeCatch {
  height: 200px;
  overflow: hidden; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    section.eyeCatch {
      height: 250px;
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    section.eyeCatch {
      height: 400px;
      overflow: hidden; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    section.eyeCatch {
      height: 500px;
      overflow: hidden; } }
  @media only screen and (min-width: 1200px) {
    section.eyeCatch {
      height: 600px;
      overflow: hidden; } }

section.contentEyeCatch {
  height: 100px;
  overflow: hidden; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    section.contentEyeCatch {
      height: 150px;
      overflow: hidden; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    section.contentEyeCatch {
      height: 300px;
      overflow: hidden; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    section.contentEyeCatch {
      height: 300px;
      overflow: hidden; } }
  @media only screen and (min-width: 1200px) {
    section.contentEyeCatch {
      height: 300px;
      overflow: hidden; } }
  section.contentEyeCatch img {
    width: 100%; }

section {
  /**
  各背景指定
   */ }
  section.newsList {
    background-color: #FFF;
    color: #333; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.newsList {
        background-color: #293336;
        color: #afafaf; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.newsList {
        background-color: #293336;
        color: #afafaf; } }
    @media only screen and (min-width: 1200px) {
      section.newsList {
        background-color: #293336;
        color: #afafaf; } }
    section.newsList h2 {
      font-size: 20px;
      font-size: 1.25rem;
      text-align: center; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section.newsList h2 {
          text-align: center; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.newsList h2 {
          font-size: 20px;
          font-size: 1.25rem;
          margin: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.newsList h2 {
          font-size: 24px;
          font-size: 1.5rem;
          margin: 0; } }
      @media only screen and (min-width: 1200px) {
        section.newsList h2 {
          font-size: 24px;
          font-size: 1.5rem;
          margin: 0; } }
    section.newsList span.newsCategory {
      background-color: #21c8dd;
      display: inline-block;
      padding: 4px 20px 3px 20px;
      color: #FFF;
      margin-right: 10px;
      font-size: 10px;
      font-size: 0.625rem; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section.newsList span.newsCategory {
          padding: 4px 20px 3px 20px;
          margin-right: 10px;
          font-size: 10px;
          font-size: 0.625rem; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.newsList span.newsCategory {
          padding: 6px 20px 5px 20px;
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.newsList span.newsCategory {
          padding: 6px 20px 5px 20px;
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.newsList span.newsCategory {
          padding: 6px 20px 5px 20px;
          font-size: 13px;
          font-size: 0.8125rem; } }
    section.newsList span.newsTitle {
      display: inline-block;
      margin-right: 10px;
      font-size: 10px;
      font-size: 0.625rem; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section.newsList span.newsTitle {
          margin-right: 10px;
          font-size: 10px;
          font-size: 0.625rem; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.newsList span.newsTitle {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.newsList span.newsTitle {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.newsList span.newsTitle {
          font-size: 13px;
          font-size: 0.8125rem; } }
    section.newsList time {
      display: inline-block;
      margin-right: 10px;
      font-size: 10px;
      font-size: 0.625rem; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section.newsList time {
          margin-right: 10px;
          font-size: 10px;
          font-size: 0.625rem; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.newsList time {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.newsList time {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.newsList time {
          font-size: 13px;
          font-size: 0.8125rem; } }
    section.newsList p.newsText {
      padding: 15px 0;
      font-size: 10px;
      font-size: 0.625rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.newsList p.newsText {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.newsList p.newsText {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.newsList p.newsText {
          font-size: 13px;
          font-size: 0.8125rem; } }
    section.newsList .newsAccordion {
      display: block; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section.newsList .newsAccordion {
          display: block; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.newsList .newsAccordion {
          display: none; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.newsList .newsAccordion {
          display: none; } }
      @media only screen and (min-width: 1200px) {
        section.newsList .newsAccordion {
          display: none; } }
    section.newsList .newsLinkBox {
      margin-top: -5px;
      text-align: right; }
      section.newsList .newsLinkBox img {
        max-width: 150px; }
  section.topCompany .companyBox {
    margin-bottom: 2.5rem;
    text-align: center; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topCompany .companyBox {
        text-align: center; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topCompany .companyBox {
        text-align: left; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topCompany .companyBox {
        text-align: left; } }
    @media only screen and (min-width: 1200px) {
      section.topCompany .companyBox {
        text-align: left; } }
  section.topCompany h3.companyTitle {
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: bold;
    margin: 0 0 10px; }
  section.topCompany p {
    margin: 5px 0 8px;
    font-size: 11px;
    font-size: 0.6875rem; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topCompany p {
        font-size: 11px;
        font-size: 0.6875rem; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topCompany p {
        font-size: 14px;
        font-size: 0.875rem; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topCompany p {
        font-size: 14px;
        font-size: 0.875rem; } }
    @media only screen and (min-width: 1200px) {
      section.topCompany p {
        font-size: 14px;
        font-size: 0.875rem; } }
  section.bgGray {
    background-color: #f9f9f9; }
  section.topStart {
    background-image: url("../../images/top_start.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topStart {
        background-size: auto;
        background-position: right center; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topStart {
        background-size: auto;
        background-position: right center; } }
    @media only screen and (min-width: 1200px) {
      section.topStart {
        background-size: auto;
        background-position: right center; } }
  section.topContribution {
    background-image: url("../../images/top_contribution.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topContribution {
        background-size: auto;
        background-position: left center; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topContribution {
        background-size: auto;
        background-position: left center; } }
    @media only screen and (min-width: 1200px) {
      section.topContribution {
        background-size: auto;
        background-position: left center; } }
    section.topContribution .contentBox {
      margin: 100px 15px 50px 15px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.topContribution .contentBox {
          margin: 50px 0 50px auto; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.topContribution .contentBox {
          margin: 50px 0 50px auto; } }
      @media only screen and (min-width: 1200px) {
        section.topContribution .contentBox {
          margin: 50px 0 50px auto; } }
  section.topPhilosophy {
    background-image: url("../../images/top_philosophy.jpg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhilosophy {
        background-size: auto;
        background-position: right center; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhilosophy {
        background-size: auto;
        background-position: right center; } }
    @media only screen and (min-width: 1200px) {
      section.topPhilosophy {
        background-size: auto;
        background-position: right center; } }
  section .contentBox {
    background-color: #FFF;
    padding: 20px;
    margin: 100px 15px 50px 15px; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section .contentBox {
        padding: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section .contentBox {
        margin: 50px 0 50px 0;
        padding: 30px;
        width: 400px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section .contentBox {
        margin: 50px 0 50px 0;
        padding: 30px;
        width: 400px; } }
    @media only screen and (min-width: 1200px) {
      section .contentBox {
        margin: 50px 0 50px 0;
        padding: 30px;
        width: 400px; } }
    section .contentBox h2 {
      position: relative;
      color: #999999;
      text-align: center;
      font-size: 22px;
      font-size: 1.375rem;
      margin: 0 auto 20px; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section .contentBox h2 {
          font-size: 22px;
          font-size: 1.375rem;
          margin: 0 auto 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section .contentBox h2 {
          font-size: 25px;
          font-size: 1.5625rem;
          margin: 0 auto 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section .contentBox h2 {
          font-size: 25px;
          font-size: 1.5625rem;
          margin: 0 auto 30px; } }
      @media only screen and (min-width: 1200px) {
        section .contentBox h2 {
          font-size: 25px;
          font-size: 1.5625rem;
          margin: 0 auto 30px; } }
      section .contentBox h2:before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -5px;
        /*下線の上下位置調整*/
        display: inline-block;
        width: 75px;
        /*下線の幅*/
        height: 2px;
        /*下線の太さ*/
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translate(-50%);
        /*位置調整*/
        background-color: #999999;
        /*下線の色*/ }
    section .contentBox p {
      font-size: 11px;
      font-size: 0.6875rem; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section .contentBox p {
          font-size: 11px;
          font-size: 0.6875rem; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section .contentBox p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section .contentBox p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section .contentBox p {
          font-size: 13px;
          font-size: 0.8125rem; } }
  section.topPhotoBlock .topPhotoLeft {
    width: 100%; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topPhotoBlock .topPhotoLeft {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhotoBlock .topPhotoLeft {
        width: 50%;
        float: left; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhotoBlock .topPhotoLeft {
        width: 50%;
        float: left; } }
    @media only screen and (min-width: 1200px) {
      section.topPhotoBlock .topPhotoLeft {
        width: 50%;
        float: left; } }
  section.topPhotoBlock .topPhotoRight {
    width: 100%; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topPhotoBlock .topPhotoRight {
        width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhotoBlock .topPhotoRight {
        width: 50%;
        float: right; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhotoBlock .topPhotoRight {
        width: 50%;
        float: right; } }
    @media only screen and (min-width: 1200px) {
      section.topPhotoBlock .topPhotoRight {
        width: 50%;
        float: right; } }
  section.topPhotoBlock .photoListBox {
    background-image: url("../../images/photo_list.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 250px;
    position: relative; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topPhotoBlock .photoListBox {
        height: 150px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhotoBlock .photoListBox {
        height: 500px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhotoBlock .photoListBox {
        height: 500px; } }
    @media only screen and (min-width: 1200px) {
      section.topPhotoBlock .photoListBox {
        height: 500px; } }
    section.topPhotoBlock .photoListBox .photoListBtnBox {
      position: absolute;
      bottom: 20px;
      right: 20px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.topPhotoBlock .photoListBox .photoListBtnBox {
          bottom: 30px;
          right: 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.topPhotoBlock .photoListBox .photoListBtnBox {
          bottom: 30px;
          right: 30px; } }
      @media only screen and (min-width: 1200px) {
        section.topPhotoBlock .photoListBox .photoListBtnBox {
          bottom: 30px;
          right: 30px; } }
      section.topPhotoBlock .photoListBox .photoListBtnBox img {
        max-width: 150px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.topPhotoBlock .photoListBox .photoListBtnBox img {
            max-width: 172px; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.topPhotoBlock .photoListBox .photoListBtnBox img {
            max-width: 172px; } }
        @media only screen and (min-width: 1200px) {
          section.topPhotoBlock .photoListBox .photoListBtnBox img {
            max-width: 172px; } }
  section.topPhotoBlock .cultureBox {
    background-image: url("../../images/culture.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    width: 50%;
    float: left; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topPhotoBlock .cultureBox {
        height: 150px;
        width: 50%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhotoBlock .cultureBox {
        height: 250px;
        width: 100%; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhotoBlock .cultureBox {
        height: 250px;
        width: 100%; } }
    @media only screen and (min-width: 1200px) {
      section.topPhotoBlock .cultureBox {
        height: 250px;
        width: 100%; } }
  section.topPhotoBlock .aboutUsBox {
    background-image: url("../../images/about_us.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    width: 50%;
    float: right; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.topPhotoBlock .aboutUsBox {
        height: 150px;
        width: 50%; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhotoBlock .aboutUsBox {
        height: 250px;
        width: 100%; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhotoBlock .aboutUsBox {
        height: 250px;
        width: 100%; } }
    @media only screen and (min-width: 1200px) {
      section.topPhotoBlock .aboutUsBox {
        height: 250px;
        width: 100%; } }
  section.topPhotoBlock .cultureBox, section.topPhotoBlock .aboutUsBox {
    position: relative; }
    section.topPhotoBlock .cultureBox .topPhotoRightBtn, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn {
      position: absolute;
      bottom: 10px;
      right: 10px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.topPhotoBlock .cultureBox .topPhotoRightBtn, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn {
          bottom: 30px;
          right: 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.topPhotoBlock .cultureBox .topPhotoRightBtn, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn {
          bottom: 30px;
          right: 30px; } }
      @media only screen and (min-width: 1200px) {
        section.topPhotoBlock .cultureBox .topPhotoRightBtn, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn {
          bottom: 30px;
          right: 30px; } }
      section.topPhotoBlock .cultureBox .topPhotoRightBtn img, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn img {
        max-width: 30px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.topPhotoBlock .cultureBox .topPhotoRightBtn img, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn img {
            max-width: 50px; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.topPhotoBlock .cultureBox .topPhotoRightBtn img, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn img {
            max-width: 50px; } }
        @media only screen and (min-width: 1200px) {
          section.topPhotoBlock .cultureBox .topPhotoRightBtn img, section.topPhotoBlock .aboutUsBox .topPhotoRightBtn img {
            max-width: 50px; } }
  section.topPhotoBlock .jobListBox {
    background-image: url("../../images/job_list.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    height: 140px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.topPhotoBlock .jobListBox {
        height: 300px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.topPhotoBlock .jobListBox {
        height: 300px; } }
    @media only screen and (min-width: 1200px) {
      section.topPhotoBlock .jobListBox {
        height: 300px; } }
    section.topPhotoBlock .jobListBox h3 {
      margin-top: 50px;
      font-size: 25px;
      font-size: 1.5625rem; }
    section.topPhotoBlock .jobListBox .topJobListBtn {
      margin-top: 25px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.topPhotoBlock .jobListBox .topJobListBtn {
          margin-top: 40px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.topPhotoBlock .jobListBox .topJobListBtn {
          margin-top: 40px; } }
      @media only screen and (min-width: 1200px) {
        section.topPhotoBlock .jobListBox .topJobListBtn {
          margin-top: 40px; } }
      section.topPhotoBlock .jobListBox .topJobListBtn img {
        max-width: 250px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.topPhotoBlock .jobListBox .topJobListBtn img {
            max-width: 300px; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.topPhotoBlock .jobListBox .topJobListBtn img {
            max-width: 300px; } }
        @media only screen and (min-width: 1200px) {
          section.topPhotoBlock .jobListBox .topJobListBtn img {
            max-width: 300px; } }
  section.topPhotoBlock .overBlue {
    background-color: rgba(59, 65, 114, 0.6);
    height: 100%;
    padding: 20px; }
  section.topPhotoBlock .overGreen {
    background-color: rgba(7, 57, 25, 0.4);
    height: 100%;
    padding: 20px; }
  section.topPhotoBlock h3.underLine {
    font-weight: bold;
    position: relative;
    display: inline-block;
    font-size: 20px;
    font-size: 1.25rem; }
    section.topPhotoBlock h3.underLine:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      /*下線の上下位置調整*/
      display: inline-block;
      width: 75px;
      /*下線の幅*/
      height: 2px;
      /*下線の太さ*/
      background-color: #f7931e;
      /*下線の色*/ }
  section.topPhotoBlock h3.white {
    color: #FFF; }
  section.sns .snsLeft {
    padding: 20px 0;
    width: 40%;
    float: left;
    display: none; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      section.sns .snsLeft {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.sns .snsLeft {
        display: block; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.sns .snsLeft {
        display: block; } }
    @media only screen and (min-width: 1200px) {
      section.sns .snsLeft {
        display: block; } }
    section.sns .snsLeft p {
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 10px;
      font-size: 0.625rem; }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        section.sns .snsLeft p {
          font-size: 10px;
          font-size: 0.625rem; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.sns .snsLeft p {
          font-size: 12px;
          font-size: 0.75rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.sns .snsLeft p {
          font-size: 14px;
          font-size: 0.875rem; } }
      @media only screen and (min-width: 1200px) {
        section.sns .snsLeft p {
          font-size: 17px;
          font-size: 1.0625rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    section.sns .snsRight {
      width: 60%;
      float: right; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    section.sns .snsRight {
      width: 60%;
      float: right; } }
  @media only screen and (min-width: 1200px) {
    section.sns .snsRight {
      width: 60%;
      float: right; } }

h2.employeeTitle {
  font-weight: bold; }

p.employeeRead {
  font-size: 12px;
  font-size: 0.75rem; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    p.employeeRead {
      font-size: 12px;
      font-size: 0.75rem; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    p.employeeRead {
      font-size: 13px;
      font-size: 0.8125rem; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    p.employeeRead {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 1200px) {
    p.employeeRead {
      font-size: 15px;
      font-size: 0.9375rem; } }

.card.cardEmployee {
  border: none;
  margin-left: auto;
  margin-right: auto;
  width: 90%; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .card.cardEmployee {
      width: 90%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .card.cardEmployee {
      width: 100%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .card.cardEmployee {
      width: 100%; } }
  @media only screen and (min-width: 1200px) {
    .card.cardEmployee {
      width: 100%; } }

h4.cardEmployeeName {
  background-color: #21c8dd;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  color: #FFF;
  font-size: 16px;
  font-size: 1rem; }

p.cardProfile {
  color: #797979;
  font-size: 15px;
  font-size: 0.9375rem;
  margin: 10px 0; }

p.cardProfileText {
  font-size: 12px;
  font-size: 0.75rem; }

section.contentPage {
  /**
    インタビューページ
     */ }
  section.contentPage h1.mainTitle {
    margin-top: 30px;
    margin-bottom: 10px;
    color: #145865;
    font-size: 30px;
    font-size: 1.875rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage h1.mainTitle {
        font-size: 35px;
        font-size: 2.1875rem; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage h1.mainTitle {
        font-size: 40px;
        font-size: 2.5rem; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage h1.mainTitle {
        font-size: 45px;
        font-size: 2.8125rem; } }
  section.contentPage h2.centerTitle {
    color: #145865;
    text-align: center;
    margin: .5rem 0 1rem;
    font-size: 17px;
    font-size: 1.0625rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage h2.centerTitle {
        font-size: 30px;
        font-size: 1.875rem; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage h2.centerTitle {
        font-size: 30px;
        font-size: 1.875rem; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage h2.centerTitle {
        font-size: 25px;
        font-size: 1.5625rem; } }
    section.contentPage h2.centerTitle:before {
      content: "―";
      letter-spacing: -2px; }
    section.contentPage h2.centerTitle:after {
      content: "―";
      letter-spacing: -2px; }
    section.contentPage h2.centerTitle.mt3rem {
      margin-top: 3rem; }
  section.contentPage h2.centerTitle2 {
    text-align: center;
    color: #145865;
    position: relative;
    display: inline-block;
    padding: 0 30px;
    font-size: 17px;
    font-size: 1.0625rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage h2.centerTitle2 {
        font-size: 30px;
        font-size: 1.875rem; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage h2.centerTitle2 {
        font-size: 30px;
        font-size: 1.875rem; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage h2.centerTitle2 {
        font-size: 25px;
        font-size: 1.5625rem; } }
    section.contentPage h2.centerTitle2:before, section.contentPage h2.centerTitle2:after {
      content: '';
      position: absolute;
      top: 50%;
      display: inline-block;
      width: 20px;
      height: 1px;
      background-color: #145865; }
    section.contentPage h2.centerTitle2:before {
      left: 0; }
    section.contentPage h2.centerTitle2:after {
      right: 0; }
  section.contentPage .aboutUsTitle {
    background-color: #dfe4eb;
    position: relative;
    height: 250px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage .aboutUsTitle {
        overflow: hidden;
        height: 250px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage .aboutUsTitle {
        overflow: hidden;
        height: 300px; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage .aboutUsTitle {
        overflow: hidden;
        height: 300px; } }
    section.contentPage .aboutUsTitle img {
      display: block;
      position: absolute;
      width: 90%;
      top: 45px;
      right: -15px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .aboutUsTitle img {
          width: 80%;
          top: 0;
          right: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .aboutUsTitle img {
          width: 80%;
          top: 0;
          right: 0; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .aboutUsTitle img {
          width: 80%;
          top: 0;
          right: 0; } }
    section.contentPage .aboutUsTitle h2 {
      display: -webkit-flex;
      display: flex;
      background-color: #607899;
      color: #FFF;
      padding: 10px 20px;
      position: absolute;
      -webkit-align-items: center;
      /* 縦方向中央揃え（Safari用） */
      align-items: center;
      /* 縦方向中央揃え */
      font-size: 18px;
      font-size: 1.125rem;
      height: 80px;
      top: 20px;
      left: -15px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .aboutUsTitle h2 {
          font-size: 25px;
          font-size: 1.5625rem;
          top: 70px;
          left: 0;
          height: 100px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .aboutUsTitle h2 {
          font-size: 30px;
          font-size: 1.875rem;
          left: 0;
          top: 100px;
          height: 100px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .aboutUsTitle h2 {
          font-size: 30px;
          font-size: 1.875rem;
          left: 0;
          top: 100px;
          height: 100px; } }
  section.contentPage .aboutUsContainer {
    margin: 0 0 30px 0; }
    section.contentPage .aboutUsContainer h3 {
      color: #607899;
      font-weight: bold;
      font-size: 16px;
      font-size: 1rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .aboutUsContainer h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .aboutUsContainer h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .aboutUsContainer h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
      section.contentPage .aboutUsContainer h3:before {
        content: "―"; }
    section.contentPage .aboutUsContainer p {
      line-height: 170%;
      font-size: 11px;
      font-size: 0.6875rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .aboutUsContainer p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .aboutUsContainer p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .aboutUsContainer p {
          font-size: 13px;
          font-size: 0.8125rem; } }
    section.contentPage .aboutUsContainer img.aboutImageLeft {
      float: left;
      margin: 0 15px 10px 0;
      width: 130px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .aboutUsContainer img.aboutImageLeft {
          margin: 0 30px 30px 0;
          width: 250px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .aboutUsContainer img.aboutImageLeft {
          margin: 0 30px 30px 0;
          width: 250px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .aboutUsContainer img.aboutImageLeft {
          margin: 0 30px 30px 0;
          width: 250px; } }
    section.contentPage .aboutUsContainer img.aboutImageRight {
      float: right;
      margin: 0 0 10px 15px;
      width: 130px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .aboutUsContainer img.aboutImageRight {
          margin: 0 0 30px 30px;
          width: 250px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .aboutUsContainer img.aboutImageRight {
          margin: 0 0 30px 30px;
          width: 250px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .aboutUsContainer img.aboutImageRight {
          margin: 0 0 30px 30px;
          width: 250px; } }
  section.contentPage .cultureTitle {
    background-color: #dfe4eb;
    position: relative;
    height: 250px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage .cultureTitle {
        overflow: hidden;
        height: 250px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage .cultureTitle {
        overflow: hidden;
        height: 300px; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage .cultureTitle {
        overflow: hidden;
        height: 300px; } }
    section.contentPage .cultureTitle img {
      display: block;
      position: absolute;
      width: 90%;
      top: 45px;
      right: -15px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .cultureTitle img {
          width: 80%;
          top: 0;
          right: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .cultureTitle img {
          width: 80%;
          top: 0;
          right: 0; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .cultureTitle img {
          width: 80%;
          top: 0;
          right: 0; } }
    section.contentPage .cultureTitle h2 {
      background-color: #607899;
      color: #FFF;
      padding: 20px 30px;
      position: absolute;
      font-size: 25px;
      font-size: 1.5625rem;
      top: 20px;
      left: -15px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .cultureTitle h2 {
          font-size: 36px;
          font-size: 2.25rem;
          top: 70px;
          left: 0; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .cultureTitle h2 {
          font-size: 36px;
          font-size: 2.25rem;
          left: 0;
          top: 100px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .cultureTitle h2 {
          font-size: 36px;
          font-size: 2.25rem;
          left: 0;
          top: 100px; } }
      section.contentPage .cultureTitle h2 small {
        display: block;
        font-size: 45%; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage .cultureTitle h2 small {
            font-size: 35%; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage .cultureTitle h2 small {
            font-size: 35%; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage .cultureTitle h2 small {
            font-size: 35%; } }
  section.contentPage .cultureContent {
    margin: 40px 0 0 0; }
    section.contentPage .cultureContent h3 {
      color: #607899;
      font-weight: bold;
      margin: 0 0 15px 0;
      font-size: 16px;
      font-size: 1rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .cultureContent h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .cultureContent h3 {
          font-size: 19px;
          font-size: 1.1875rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .cultureContent h3 {
          font-size: 20px;
          font-size: 1.25rem; } }
      section.contentPage .cultureContent h3:before {
        content: "―"; }
    section.contentPage .cultureContent p {
      line-height: 170%;
      font-size: 10px;
      font-size: 0.625rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .cultureContent p {
          font-size: 12px;
          font-size: 0.75rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .cultureContent p {
          font-size: 12px;
          font-size: 0.75rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .cultureContent p {
          font-size: 12px;
          font-size: 0.75rem; } }
  section.contentPage .jobContentBg {
    background-color: #eff1f5; }
  section.contentPage .jobContentPadding {
    padding: 15px 15px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage .jobContentPadding {
        padding: 20px 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage .jobContentPadding {
        padding: 20px 30px; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage .jobContentPadding {
        padding: 20px 30px; } }
  section.contentPage .jobContent {
    margin: 10px 0; }
    section.contentPage .jobContent h3 {
      color: #000000;
      font-size: 14px;
      font-size: 0.875rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .jobContent h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .jobContent h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .jobContent h3 {
          font-size: 18px;
          font-size: 1.125rem; } }
    section.contentPage .jobContent p {
      margin: 5px 0;
      font-size: 11px;
      font-size: 0.6875rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .jobContent p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .jobContent p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .jobContent p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      section.contentPage .jobContent p.jobCatch {
        margin: 8px 0;
        font-size: 16px;
        font-size: 1rem; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage .jobContent p.jobCatch {
            font-weight: bold;
            font-size: 18px;
            font-size: 1.125rem; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage .jobContent p.jobCatch {
            font-weight: bold;
            font-size: 18px;
            font-size: 1.125rem; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage .jobContent p.jobCatch {
            font-weight: bold;
            font-size: 18px;
            font-size: 1.125rem; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage .jobContent dl {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000000;
        border-top: none; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage .jobContent dl {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000000;
        border-top: none; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage .jobContent dl {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #000000;
        border-top: none; } }
    section.contentPage .jobContent dl dt {
      background: #a0aec2;
      box-sizing: border-box;
      color: #FFF;
      text-align: center;
      width: 100%;
      padding: 8px;
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .jobContent dl dt {
          padding: 20px 0;
          font-size: 14px;
          font-size: 0.875rem;
          width: 20%;
          border-top: 1px solid #000000; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .jobContent dl dt {
          padding: 20px 0;
          font-size: 14px;
          font-size: 0.875rem;
          width: 20%;
          border-top: 1px solid #000000; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .jobContent dl dt {
          padding: 20px 0;
          font-size: 14px;
          font-size: 0.875rem;
          width: 15%;
          border-top: 1px solid #000000; } }
    section.contentPage .jobContent dl dd {
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
      padding: 8px;
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .jobContent dl dd {
          border-left: 1px solid #000000;
          border-top: 1px solid #000000;
          width: 80%;
          padding: 20px 10px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .jobContent dl dd {
          border-left: 1px solid #000000;
          border-top: 1px solid #000000;
          width: 80%;
          padding: 20px 10px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .jobContent dl dd {
          border-left: 1px solid #000000;
          border-top: 1px solid #000000;
          width: 85%;
          padding: 20px 10px;
          font-size: 14px;
          font-size: 0.875rem; } }
  section.contentPage .jobBtnBlock {
    margin-top: 30px;
    margin-bottom: 50px; }
  section.contentPage .interviewList {
    margin: 30px 0 50px; }
    section.contentPage .interviewList .cardInterview {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      border: none;
      width: 80%;
      margin: 0 auto 20px auto; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .interviewList .cardInterview {
          width: 100%; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .interviewList .cardInterview {
          width: 100%; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .interviewList .cardInterview {
          width: 100%; } }
      section.contentPage .interviewList .cardInterview .card-body {
        text-align: center;
        background-color: #f2f2f2; }
        section.contentPage .interviewList .cardInterview .card-body h5 {
          margin: 5px 0 2px; }
        section.contentPage .interviewList .cardInterview .card-body p {
          margin: 0; }
          section.contentPage .interviewList .cardInterview .card-body p.year {
            font-size: 14px;
            font-size: 0.875rem; }
          section.contentPage .interviewList .cardInterview .card-body p.engName {
            font-size: 13px;
            font-size: 0.8125rem; }
          section.contentPage .interviewList .cardInterview .card-body p.comment {
            margin: 8px 0;
            font-size: 15px;
            font-size: 0.9375rem; }
          section.contentPage .interviewList .cardInterview .card-body p.position {
            color: #607899;
            font-size: 13px;
            font-size: 0.8125rem; }
      section.contentPage .interviewList .cardInterview a {
        background-color: #000; }
        section.contentPage .interviewList .cardInterview a .interviewImage {
          position: relative; }
          section.contentPage .interviewList .cardInterview a .interviewImage img.userImage {
            transition-duration: 0.3s; }
          section.contentPage .interviewList .cardInterview a .interviewImage .interviewImageInner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            display: none; }
            section.contentPage .interviewList .cardInterview a .interviewImage .interviewImageInner h5 {
              color: #FFF;
              font-weight: bold;
              margin: 100px auto 100px auto; }
        section.contentPage .interviewList .cardInterview a:hover .interviewImage img.userImage {
          transition-duration: 0.3s;
          opacity: 0.6; }
        section.contentPage .interviewList .cardInterview a:hover .interviewImage .interviewImageInner {
          transition-duration: 0.3s;
          display: block; }
  section.contentPage .presidentMessage {
    margin: 30px 0 20px; }
    section.contentPage .presidentMessage img.presidentImage {
      float: left;
      margin: 0 20px 20px 0;
      width: 170px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .presidentMessage img.presidentImage {
          width: 280px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .presidentMessage img.presidentImage {
          width: 280px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .presidentMessage img.presidentImage {
          width: 280px; } }
    section.contentPage .presidentMessage p {
      line-height: 200%;
      font-size: 11px;
      font-size: 0.6875rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .presidentMessage p {
          font-size: 12px;
          font-size: 0.75rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .presidentMessage p {
          font-size: 12px;
          font-size: 0.75rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .presidentMessage p {
          font-size: 12px;
          font-size: 0.75rem; } }
  section.contentPage .cardBusiness {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none; }
    section.contentPage .cardBusiness h5.card-header {
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      background-color: #607899;
      color: #FFF;
      text-align: center;
      font-size: 13px;
      font-size: 0.8125rem;
      font-weight: bold;
      border: none; }
    section.contentPage .cardBusiness .card-body {
      padding: 1rem;
      background-color: #f4f4f4; }
      section.contentPage .cardBusiness .card-body .cardBusinessImage {
        width: 30%;
        margin: 0 auto; }
      section.contentPage .cardBusiness .card-body p {
        margin: 10px 0;
        font-size: 10px;
        font-size: 0.625rem; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage .cardBusiness .card-body p {
            font-size: 12px;
            font-size: 0.75rem; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage .cardBusiness .card-body p {
            font-size: 12px;
            font-size: 0.75rem; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage .cardBusiness .card-body p {
            font-size: 12px;
            font-size: 0.75rem; } }
  section.contentPage .jobStrong {
    line-height: 150%;
    margin: 30px 0 20px; }
    section.contentPage .jobStrong p {
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .jobStrong p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .jobStrong p {
          font-size: 13px;
          font-size: 0.8125rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .jobStrong p {
          font-size: 13px;
          font-size: 0.8125rem; } }
  section.contentPage a.interviewLink .jobInterview {
    color: #FFF;
    background-color: #000;
    border: 3px solid #145865;
    font-size: 12px;
    font-size: 0.75rem;
    margin: 0 auto 30px auto;
    width: 60%;
    position: relative; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage a.interviewLink .jobInterview {
        width: 100%; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage a.interviewLink .jobInterview {
        width: 100%; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage a.interviewLink .jobInterview {
        width: 100%; } }
    section.contentPage a.interviewLink .jobInterview img.userImage {
      transition-duration: 0.3s;
      width: 100%; }
    section.contentPage a.interviewLink .jobInterview .jobInterviewInner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      padding: 30px 20px 0 20px; }
      @media only screen and (min-width: 375px) and (max-width: 575px) {
        section.contentPage a.interviewLink .jobInterview .jobInterviewInner {
          padding: 40px 30px 0 30px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage a.interviewLink .jobInterview .jobInterviewInner {
          padding: 70px 50px 0 50px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage a.interviewLink .jobInterview .jobInterviewInner {
          padding: 70px 50px 0 50px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage a.interviewLink .jobInterview .jobInterviewInner {
          padding: 70px 50px 0 50px; } }
      section.contentPage a.interviewLink .jobInterview .jobInterviewInner h5 {
        margin: 0 0 10px 0; }
      section.contentPage a.interviewLink .jobInterview .jobInterviewInner p.position {
        margin: 0; }
      section.contentPage a.interviewLink .jobInterview .jobInterviewInner p.profile {
        font-size: 10px;
        font-size: 0.625rem;
        margin: 0 0 0 0; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner p.profile {
            font-size: 13px;
            font-size: 0.8125rem;
            margin: 0 0 10px 0; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner p.profile {
            font-size: 13px;
            font-size: 0.8125rem;
            margin: 0 0 10px 0; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner p.profile {
            font-size: 13px;
            font-size: 0.8125rem;
            margin: 0 0 10px 0; } }
      section.contentPage a.interviewLink .jobInterview .jobInterviewInner img {
        display: block;
        width: 80px;
        margin: 20px auto 0; }
        @media only screen and (min-width: 375px) and (max-width: 575px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner img {
            margin: 40px auto 0; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner img {
            width: 100px;
            margin: 80px auto 0; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner img {
            width: 100px;
            margin: 80px auto 0; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage a.interviewLink .jobInterview .jobInterviewInner img {
            width: 100px;
            margin: 80px auto 0; } }
  section.contentPage a.interviewLink:hover img.userImage {
    transition-duration: 0.3s;
    opacity: 0.4; }
  section.contentPage a.interviewLink:hover .jobInterviewInner {
    display: block; }
  section.contentPage .jobListBlock {
    margin: 20px 0 50px; }
    section.contentPage .jobListBlock h3 {
      border-bottom: 3px solid #145865; }
      section.contentPage .jobListBlock h3 span {
        display: inline-block;
        background-color: #145865;
        color: #FFF;
        padding: 10px 50px 6px;
        font-size: 18px;
        font-size: 1.125rem; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage .jobListBlock h3 span {
            font-size: 20px;
            font-size: 1.25rem; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage .jobListBlock h3 span {
            font-size: 20px;
            font-size: 1.25rem; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage .jobListBlock h3 span {
            font-size: 20px;
            font-size: 1.25rem; } }
    section.contentPage .jobListBlock .jobContentBlock {
      margin: 30px 0 0;
      background-color: #f4f4f4;
      position: relative;
      padding: 20px 20px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .jobListBlock .jobContentBlock {
          padding: 20px 30px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .jobListBlock .jobContentBlock {
          padding: 20px 30px; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .jobListBlock .jobContentBlock {
          padding: 20px 35px; } }
      section.contentPage .jobListBlock .jobContentBlock h4 {
        color: #607899;
        font-size: 15px;
        font-size: 0.9375rem; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage .jobListBlock .jobContentBlock h4 {
            font-size: 16px;
            font-size: 1rem; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage .jobListBlock .jobContentBlock h4 {
            font-size: 16px;
            font-size: 1rem; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage .jobListBlock .jobContentBlock h4 {
            font-size: 16px;
            font-size: 1rem; } }
      section.contentPage .jobListBlock .jobContentBlock p {
        color: #000;
        margin-bottom: 1.5rem;
        font-size: 10px;
        font-size: 0.625rem; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          section.contentPage .jobListBlock .jobContentBlock p {
            font-size: 12px;
            font-size: 0.75rem; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          section.contentPage .jobListBlock .jobContentBlock p {
            font-size: 12px;
            font-size: 0.75rem; } }
        @media only screen and (min-width: 1200px) {
          section.contentPage .jobListBlock .jobContentBlock p {
            font-size: 12px;
            font-size: 0.75rem; } }
      section.contentPage .jobListBlock .jobContentBlock a.readMore {
        position: absolute;
        bottom: 0;
        right: 0; }
        section.contentPage .jobListBlock .jobContentBlock a.readMore img {
          width: 100px; }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            section.contentPage .jobListBlock .jobContentBlock a.readMore img {
              width: 120px; } }
          @media only screen and (min-width: 992px) and (max-width: 1199px) {
            section.contentPage .jobListBlock .jobContentBlock a.readMore img {
              width: 120px; } }
          @media only screen and (min-width: 1200px) {
            section.contentPage .jobListBlock .jobContentBlock a.readMore img {
              width: 120px; } }
  section.contentPage .thanksContent {
    margin: 30px 0 60px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage .thanksContent {
        margin: 50px 0 100px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage .thanksContent {
        margin: 50px 0 100px; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage .thanksContent {
        margin: 50px 0 100px; } }
    section.contentPage .thanksContent p {
      line-height: 200%;
      margin: 25px 0;
      font-size: 13px;
      font-size: 0.8125rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .thanksContent p {
          font-size: 15px;
          font-size: 0.9375rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .thanksContent p {
          font-size: 15px;
          font-size: 0.9375rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .thanksContent p {
          font-size: 16px;
          font-size: 1rem; } }
  section.contentPage.bgSpGray {
    background-color: #f4f4f4; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage.bgSpGray {
        background-color: #FFF; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage.bgSpGray {
        background-color: #FFF; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage.bgSpGray {
        background-color: #FFF; } }
  section.contentPage p.entryRead {
    font-size: 12px;
    font-size: 0.75rem; }
  section.contentPage .entryForm {
    margin: 10px 0 50px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      section.contentPage .entryForm dl {
        display: flex;
        flex-wrap: wrap; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      section.contentPage .entryForm dl {
        display: flex;
        flex-wrap: wrap; } }
    @media only screen and (min-width: 1200px) {
      section.contentPage .entryForm dl {
        display: flex;
        flex-wrap: wrap; } }
    section.contentPage .entryForm dl dt {
      box-sizing: border-box;
      width: 100%;
      font-weight: normal;
      padding: 20px 0 5px;
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .entryForm dl dt {
          border-bottom: 1px solid #CCC;
          padding: 20px 0 20px;
          font-size: 14px;
          font-size: 0.875rem;
          width: 30%; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .entryForm dl dt {
          border-bottom: 1px solid #CCC;
          padding: 20px 0 20px;
          font-size: 14px;
          font-size: 0.875rem;
          width: 30%; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .entryForm dl dt {
          border-bottom: 1px solid #CCC;
          padding: 20px 0 20px;
          font-size: 14px;
          font-size: 0.875rem;
          width: 30%; } }
      section.contentPage .entryForm dl dt span {
        display: inline-block;
        padding: 4px 15px 3px;
        margin: 0 20px 0 0;
        color: #FFF; }
        section.contentPage .entryForm dl dt span.required {
          background-color: #f7931e; }
        section.contentPage .entryForm dl dt span.unRequired {
          background-color: #b3b3b3; }
    section.contentPage .entryForm dl dd {
      border-bottom: 1px solid #CCC;
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      padding: 3px 0 20px;
      font-size: 12px;
      font-size: 0.75rem; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        section.contentPage .entryForm dl dd {
          width: 70%;
          padding: 20px 0 20px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        section.contentPage .entryForm dl dd {
          width: 70%;
          padding: 20px 0 20px;
          font-size: 14px;
          font-size: 0.875rem; } }
      @media only screen and (min-width: 1200px) {
        section.contentPage .entryForm dl dd {
          width: 70%;
          padding: 20px 0 20px;
          font-size: 14px;
          font-size: 0.875rem; } }
      section.contentPage .entryForm dl dd li {
        margin: 0 0 10px; }
    section.contentPage .entryForm .inputText {
      padding: 3px 10px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      border-radius: 5px;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2; }
    section.contentPage .entryForm .inputWidthFull {
      width: 100%; }
    section.contentPage .entryForm .inputWidthBirthday {
      width: 60px; }
    section.contentPage .entryForm textarea.motiveTextarea {
      padding: 3px 10px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      border-radius: 5px;
      border: 1px solid #f2f2f2;
      background-color: #f2f2f2;
      width: 100%;
      height: 200px; }
    section.contentPage .entryForm p {
      font-size: 12px;
      font-size: 0.75rem;
      margin: 5px 0; }
    section.contentPage .entryForm .entryAgree {
      text-align: center;
      font-size: 13px;
      font-size: 0.8125rem; }
      section.contentPage .entryForm .entryAgree a {
        text-decoration: underline; }

p.topRead {
  margin: 20px 0 30px;
  font-size: 12px;
  font-size: 0.75rem; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    p.topRead {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    p.topRead {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 1200px) {
    p.topRead {
      font-size: 14px;
      font-size: 0.875rem; } }

p.read1 {
  font-size: 11px;
  font-size: 0.6875rem; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    p.read1 {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    p.read1 {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 1200px) {
    p.read1 {
      font-size: 14px;
      font-size: 0.875rem; } }

p.read2 {
  line-height: 170%;
  font-size: 11px;
  font-size: 0.6875rem; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    p.read2 {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    p.read2 {
      font-size: 14px;
      font-size: 0.875rem; } }
  @media only screen and (min-width: 1200px) {
    p.read2 {
      font-size: 14px;
      font-size: 0.875rem; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  p.pcCenter {
    text-align: center; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  p.pcCenter {
    text-align: center; } }

@media only screen and (min-width: 1200px) {
  p.pcCenter {
    text-align: center; } }

a.btnCompanyDetail {
  background-color: #21c8dd;
  border: 1px solid #21c8dd;
  padding-left: 20px;
  padding-right: 20px; }

.snsBtnBlock {
  font-size: 0; }
  .snsBtnBlock a.btnSns {
    margin: 0;
    color: #FFF;
    width: 33.333333%;
    display: inline-block;
    text-align: center;
    padding: 20px 0;
    font-size: 17px;
    font-size: 1.0625rem; }
    .snsBtnBlock a.btnSns:hover {
      text-decoration: none; }
  .snsBtnBlock a.btnFacebook {
    background-color: #3b5998; }
  .snsBtnBlock a.btnTwitter {
    background-color: #43c6f2; }
  .snsBtnBlock a.btnInstagram {
    background: linear-gradient(90deg, #f68435, #eb278e); }
  .snsBtnBlock a span.snsTitlePc {
    display: none; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .snsBtnBlock a span.snsTitlePc {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .snsBtnBlock a span.snsTitlePc {
        display: inline; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .snsBtnBlock a span.snsTitlePc {
        display: inline; } }
    @media only screen and (min-width: 1200px) {
      .snsBtnBlock a span.snsTitlePc {
        display: inline; } }
  .snsBtnBlock a span.snsTitleSp {
    display: block; }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .snsBtnBlock a span.snsTitleSp {
        display: block; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .snsBtnBlock a span.snsTitleSp {
        display: none; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .snsBtnBlock a span.snsTitleSp {
        display: none; } }
    @media only screen and (min-width: 1200px) {
      .snsBtnBlock a span.snsTitleSp {
        display: none; } }

a.btnJobEntry {
  display: block;
  background-color: #145865;
  color: #FFF;
  text-align: center;
  padding: 10px 10px;
  margin: 0 auto;
  width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    a.btnJobEntry {
      width: 60%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    a.btnJobEntry {
      width: 60%; } }
  @media only screen and (min-width: 1200px) {
    a.btnJobEntry {
      width: 60%; } }
  a.btnJobEntry:hover {
    text-decoration: none; }

button.newsClosePc {
  display: block;
  background-color: #FFF;
  float: none;
  width: 200px;
  margin: 30px auto 20px auto;
  text-shadow: none;
  opacity: 1;
  padding: 20px 0;
  font-weight: normal;
  font-size: 15px;
  font-size: 0.9375rem; }

button.confirmBtn {
  display: block;
  color: #FFF;
  background-color: #145865;
  margin: 20px auto;
  border: none;
  padding: 15px;
  width: 100%; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    button.confirmBtn {
      width: 60%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    button.confirmBtn {
      width: 60%; } }
  @media only screen and (min-width: 1200px) {
    button.confirmBtn {
      width: 60%; } }

/*****************************************************
  right-menu
*****************************************************/
.right-menu {
  position: fixed;
  right: 3.5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  .right-menu li {
    margin-bottom: 5vh; }
  .right-menu a {
    display: block;
    line-height: 0;
    position: relative; }
    .right-menu a:before {
      background-color: #f7931e;
      border-radius: 50%;
      content: "";
      display: block;
      height: 8px;
      width: 8px; }

@media screen and (min-width: 769px) {
  .right-menu a.active:before,
  .right-menu.pc-right a:hover:before {
    background-color: #f7931e; }
  .right-menu a.active:after,
  .right-menu.pc-right a:hover:after {
    border: 2px solid transparent;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 2px #f7931e;
    -moz-box-shadow: 0 0 0 2px #f7931e;
    box-shadow: 0 0 0 2px #f7931e;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 14px;
    width: 14px; } }

@media screen and (max-width: 768px) {
  .right-menu {
    display: none !important; } }

ol.breadcrumb {
  padding: 0;
  border-radius: unset;
  background-color: #e7e7e7; }
  ol.breadcrumb li.breadcrumbHome a {
    display: block;
    width: 150px;
    background-color: #145865;
    color: #21c8dd;
    text-align: center;
    padding: 6px 0 5px; }

.breadcrumb-item + .breadcrumb-item::before {
  content: ""; }

.breadcrumb-item + .breadcrumb-item {
  padding: 6px 0 5px 10px; }
  .breadcrumb-item + .breadcrumb-item a {
    color: #145865;
    text-decoration: underline; }

.breadcrumb-item.active {
  color: #000; }

.modal-content {
  background-color: transparent;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  .modal-content button.modalHeadClose {
    text-align: right;
    color: #FFF;
    text-shadow: none;
    font-size: 50px;
    font-size: 3.125rem;
    font-weight: normal; }
  .modal-content button.modalFooterClose {
    display: block;
    background-color: #FFF;
    float: none;
    width: 200px;
    margin: 30px auto 20px auto;
    text-shadow: none;
    opacity: 1;
    padding: 20px 0;
    font-weight: normal;
    font-size: 15px;
    font-size: 0.9375rem; }

.interviewModal {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  background-color: #0f353d;
  padding: 30px; }
  .interviewModal h2 {
    color: #9fdbe9;
    margin: 30px 0 20px;
    font-size: 12px;
    font-size: 0.75rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .interviewModal h2 {
        font-size: 15px;
        font-size: 0.9375rem; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .interviewModal h2 {
        font-size: 15px;
        font-size: 0.9375rem; } }
    @media only screen and (min-width: 1200px) {
      .interviewModal h2 {
        font-size: 16px;
        font-size: 1rem; } }
  .interviewModal p {
    color: #FFF;
    line-height: 200%;
    font-size: 10px;
    font-size: 0.625rem; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .interviewModal p {
        font-size: 13px;
        font-size: 0.8125rem; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .interviewModal p {
        font-size: 13px;
        font-size: 0.8125rem; } }
    @media only screen and (min-width: 1200px) {
      .interviewModal p {
        font-size: 13px;
        font-size: 0.8125rem; } }

.customMenuModal {
  background-color: #0f353d;
  color: #FFF; }
  .customMenuModal .modal-dialog {
    margin: 0; }
  .customMenuModal .modal-header {
    background-color: #0a252a;
    border-bottom: .5px solid #3b6262; }
    .customMenuModal .modal-header h5 {
      padding-top: 8px;
      padding-left: 10px;
      padding-bottom: 8px;
      font-size: 14px;
      font-size: 0.875rem; }
    .customMenuModal .modal-header .modalCloseBtn {
      color: #FFF;
      font-weight: normal;
      text-shadow: none;
      font-size: 30px;
      font-size: 1.875rem; }
  .customMenuModal .modal-body {
    padding: 0; }
  .customMenuModal ul.modalMenuMain {
    margin-bottom: 30px; }
    .customMenuModal ul.modalMenuMain li a {
      padding: 10px 30px 10px 30px;
      display: block;
      color: #FFF;
      border-bottom: .5px solid #3b6262; }
      .customMenuModal ul.modalMenuMain li a.children {
        padding: 10px 0 10px 40px;
        background-color: #3b6262;
        border-bottom: .5px solid #6bb2b2; }
      .customMenuModal ul.modalMenuMain li a i {
        float: right; }
  .customMenuModal ul.modalMenuCompany {
    font-size: 0px;
    font-size: 0rem; }
    .customMenuModal ul.modalMenuCompany li {
      padding: 0 10px;
      width: 50%;
      display: inline-block; }
      .customMenuModal ul.modalMenuCompany li a {
        display: block;
        padding: 15px 5px;
        background-color: #21c8dd;
        color: #FFF;
        font-size: 11px;
        font-size: 0.6875rem;
        text-align: center; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 0;
  z-index: 100; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: 0;
  z-index: 100; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.wrap {
  display: none; }
