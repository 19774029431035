

section {
  &.contentPage {
    h1 {
      &.mainTitle {
        margin-top: 30px;
        margin-bottom: 10px;
        color: #145865;
        @include fontsize(30);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(35);
        }
        @media #{$lg} {
          @include fontsize(40);
        }
        @media #{$xl} {
          @include fontsize(45);
        }

      }



    }


    h2 {
      &.centerTitle {
        color: #145865;
        text-align: center;
        margin: .5rem 0 1rem;
        @include fontsize(17);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(30);
        }
        @media #{$lg} {
          @include fontsize(30);
        }
        @media #{$xl} {
          @include fontsize(25);
        }
        &:before {
          content: "―";
          letter-spacing: -2px
        }
        &:after {
          content: "―";
          letter-spacing: -2px
        }
        &.mt3rem {
          margin-top: 3rem;
        }
      }

      &.centerTitle2 {
        text-align: center;
        color: #145865;
        position: relative;
        display: inline-block;
        padding: 0 30px;
        @include fontsize(17);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(30);
        }
        @media #{$lg} {
          @include fontsize(30);
        }
        @media #{$xl} {
          @include fontsize(25);
        }
        &:before, &:after {
          content: '';
          position: absolute;
          top: 50%;
          display: inline-block;
          width: 20px;
          height: 1px;
          background-color: #145865;
        }
        &:before {
          left: 0;
        }
        &:after {
          right: 0;
        }


      }

    }




    //about us
    .aboutUsTitle {
      background-color: #dfe4eb;
      position: relative;
      height: 250px;
      @media #{$sm} {
      }
      @media #{$md} {
        overflow: hidden;
        height: 250px;
      }
      @media #{$lg} {
        overflow: hidden;
        height: 300px;
      }
      @media #{$xl} {
        overflow: hidden;
        height: 300px;
      }
      img {
        display: block;
        position: absolute;
        width: 90%;
        top: 45px;
        right: -15px;
        @media #{$sm} {
        }
        @media #{$md} {
          width: 80%;
          top: 0;
          right: 0;
        }
        @media #{$lg} {
          width: 80%;
          top: 0;
          right: 0;
        }
        @media #{$xl} {
          width: 80%;
          top: 0;
          right: 0;
        }

      }
      h2 {
        display: -webkit-flex;
        display: flex;
        background-color: #607899;
        color: #FFF;
        padding: 10px 20px;
        position: absolute;
        -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
        align-items: center; /* 縦方向中央揃え */

        @include fontsize(18);
        height: 80px;
        top: 20px;
        left: -15px;
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(25);
          top: 70px;
          left: 0;
          height: 100px;
        }
        @media #{$lg} {
          @include fontsize(30);
          left: 0;
          top: 100px;
          height: 100px;
        }
        @media #{$xl} {
          @include fontsize(30);
          left: 0;
          top: 100px;
          height: 100px;
        }
      }
    }
    .aboutUsContainer {
      margin: 0 0 30px 0;
      h3 {
        color: #607899;
        font-weight: bold;
        @include fontsize(16);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(18);
        }
        @media #{$lg} {
          @include fontsize(18);
        }
        @media #{$xl} {
          @include fontsize(18);
        }
        &:before {
          content: "―";
        }
      }
      p {
        line-height: 170%;
        @include fontsize(11);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(13);
        }
        @media #{$lg} {
          @include fontsize(13);
        }
        @media #{$xl} {
          @include fontsize(13);
        }
      }
      img {
        &.aboutImageLeft {
          float: left;
          margin: 0 15px 10px 0;
          width: 130px;
          @media #{$sm} {
          }
          @media #{$md} {
            margin: 0 30px 30px 0;
            width: 250px;
          }
          @media #{$lg} {
            margin: 0 30px 30px 0;
            width: 250px;
          }
          @media #{$xl} {
            margin: 0 30px 30px 0;
            width: 250px;
          }
        }
        &.aboutImageRight {
          float: right;
          margin: 0 0 10px 15px;
          width: 130px;
          @media #{$sm} {
          }
          @media #{$md} {
            margin: 0 0 30px 30px;
            width: 250px;
          }
          @media #{$lg} {
            margin: 0 0 30px 30px;
            width: 250px;
          }
          @media #{$xl} {
            margin: 0 0 30px 30px;
            width: 250px;
          }
        }
      }
    }



    //Culture
    .cultureTitle {
      background-color: #dfe4eb;
      position: relative;
      height: 250px;
      @media #{$sm} {
      }
      @media #{$md} {
        overflow: hidden;
        height: 250px;
      }
      @media #{$lg} {
        overflow: hidden;
        height: 300px;
      }
      @media #{$xl} {
        overflow: hidden;
        height: 300px;
      }
      img {
        display: block;
        position: absolute;
        width: 90%;
        top: 45px;
        right: -15px;
        @media #{$sm} {
        }
        @media #{$md} {
          width: 80%;
          top: 0;
          right: 0;
        }
        @media #{$lg} {
          width: 80%;
          top: 0;
          right: 0;
        }
        @media #{$xl} {
          width: 80%;
          top: 0;
          right: 0;
        }

      }
      h2 {
        background-color: #607899;
        color: #FFF;
        padding: 20px 30px;
        position: absolute;
        @include fontsize(25);
        top: 20px;
        left: -15px;
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(36);
          top: 70px;
          left: 0;
        }
        @media #{$lg} {
          @include fontsize(36);
          left: 0;
          top: 100px;
        }
        @media #{$xl} {
          @include fontsize(36);
          left: 0;
          top: 100px;
        }
        small {
          display: block;
          font-size:45%;
          @media #{$sm} {
          }
          @media #{$md} {
            font-size: 35%;
          }
          @media #{$lg} {
            font-size: 35%;
          }
          @media #{$xl} {
            font-size: 35%;
          }
        }
      }
    }
    .cultureContent {
      margin: 40px 0 0 0;
      h3 {
        color: #607899;
        font-weight: bold;
        margin: 0 0 15px 0;
        @include fontsize(16);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(18);
        }
        @media #{$lg} {
          @include fontsize(19);
        }
        @media #{$xl} {
          @include fontsize(20);
        }
        &:before {
          content: "―";
        }
      }

      p {
        line-height: 170%;
        @include fontsize(10);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(12);
        }
        @media #{$lg} {
          @include fontsize(12);
        }
        @media #{$xl} {
          @include fontsize(12);
        }
      }

    }




    //募集要項
    .jobContentBg {
      background-color: #eff1f5;
    }
    .jobContentPadding {
      padding: 15px 15px;
      @media #{$sm} {
      }
      @media #{$md} {
        padding: 20px 30px;
      }
      @media #{$lg} {
        padding: 20px 30px;
      }
      @media #{$xl} {
        padding: 20px 30px;
      }

    }
    .jobContent {
      margin: 10px 0;


      h3 {
        color: #000000;
        @include fontsize(14);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(18);
        }
        @media #{$lg} {
          @include fontsize(18);
        }
        @media #{$xl} {
          @include fontsize(18);
        }
      }

      p {
        margin: 5px 0;
        @include fontsize(11);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(13);
        }
        @media #{$lg} {
          @include fontsize(13);
        }
        @media #{$xl} {
          @include fontsize(13);
        }

        &.jobCatch {
          margin: 8px 0;
          @include fontsize(16);
          @media #{$sm} {
          }
          @media #{$md} {
            font-weight: bold;
            @include fontsize(18);
          }
          @media #{$lg} {
            font-weight: bold;
            @include fontsize(18);
          }
          @media #{$xl} {
            font-weight: bold;
            @include fontsize(18);
          }
        }
      }



      dl{
        @media #{$sm} {
        }
        @media #{$md} {
          display:flex;
          flex-wrap: wrap;
          border: 1px solid #000000;
          border-top: none;
        }
        @media #{$lg} {
          display:flex;
          flex-wrap: wrap;
          border: 1px solid #000000;
          border-top: none;
        }
        @media #{$xl} {
          display:flex;
          flex-wrap: wrap;
          border: 1px solid #000000;
          border-top: none;
        }

        dt{
          background: #a0aec2;
          box-sizing: border-box;
          color: #FFF;
          text-align: center;
          width: 100%;
          padding: 8px;
          @include fontsize(12);
          @media #{$sm} {
          }
          @media #{$md} {
            padding: 20px 0;
            @include fontsize(14);
            width: 20%;
            border-top: 1px solid #000000;
          }
          @media #{$lg} {
            padding: 20px 0;
            @include fontsize(14);
            width: 20%;
            border-top: 1px solid #000000;
          }
          @media #{$xl} {
            padding: 20px 0;
            @include fontsize(14);
            width: 15%;
            border-top: 1px solid #000000;
          }
        }
        dd{
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          text-align: center;
          padding: 8px;
          @include fontsize(12);
          @media #{$sm} {
          }
          @media #{$md} {
            border-left: 1px solid #000000;
            border-top: 1px solid #000000;
            width: 80%;
            padding: 20px 10px;
            @include fontsize(14);
          }
          @media #{$lg} {
            border-left: 1px solid #000000;
            border-top: 1px solid #000000;
            width: 80%;
            padding: 20px 10px;
            @include fontsize(14);
          }
          @media #{$xl} {
            border-left: 1px solid #000000;
            border-top: 1px solid #000000;
            width: 85%;
            padding: 20px 10px;
            @include fontsize(14);
          }
        }

      }

    }
    .jobBtnBlock {
      margin-top: 30px;
      margin-bottom: 50px;
    }



    /**
    インタビューページ
     */
    .interviewList {
      margin: 30px 0 50px;

      .cardInterview {
        @include border-radius(0);
        border: none;

        width: 80%;
        margin: 0 auto 20px auto;
        @media #{$sm} {
        }
        @media #{$md} {
          width: 100%;
        }
        @media #{$lg} {
          width: 100%;
        }
        @media #{$xl} {
          width: 100%;
        }


        .card-body {
          text-align: center;
          background-color: #f2f2f2;
          h5 {
            margin: 5px 0 2px;
          }
          p {
            margin: 0;
            &.year {
              @include fontsize(14);
            }
            &.engName {
              @include fontsize(13);
            }
            &.comment {
              margin: 8px 0;
              @include fontsize(15);
            }
            &.position {
              color: #607899;
              @include fontsize(13);
            }
          }
        }

        a {
          background-color: #000;
          .interviewImage {
            position: relative;
            img {
              &.userImage {
                transition-duration: 0.3s;
              }
            }
            .interviewImageInner {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              text-align: center;
              display: none;
              h5 {
                color: #FFF;
                font-weight: bold;
                margin: 100px auto 100px auto;
              }
            }
          }
          &:hover {
            .interviewImage {
              img {
                &.userImage {
                  transition-duration: 0.3s;
                  opacity: 0.6;
                }
              }
              .interviewImageInner {
                transition-duration: 0.3s;
                display: block;
              }
            }
          }
        }

      }


    }


    // JOB LIST
    .presidentMessage {
      margin: 30px 0 20px;
      img {
        &.presidentImage {
          float: left;
          margin: 0 20px 20px 0;
          width: 170px;
          @media #{$sm} {
          }
          @media #{$md} {
            width: 280px;
          }
          @media #{$lg} {
            width: 280px;
          }
          @media #{$xl} {
            width: 280px;
          }
        }
      }
      p {
        line-height: 200%;
        @include fontsize(11);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(12);
        }
        @media #{$lg} {
          @include fontsize(12);
        }
        @media #{$xl} {
          @include fontsize(12);
        }
      }
    }
    .cardBusiness {
      @include border-radius(0);
      border: none;
      h5 {
        &.card-header {
          @include border-radius(0);
          background-color: #607899;
          color: #FFF;
          text-align: center;
          @include fontsize(13);
          font-weight: bold;
          border: none;
        }
      }
      .card-body {
        padding: 1rem;
        background-color: #f4f4f4;
        .cardBusinessImage {
          width: 30%;
          margin: 0 auto;
        }
        p {
          margin: 10px 0;
          @include fontsize(10);
          @media #{$sm} {
          }
          @media #{$md} {
            @include fontsize(12);
          }
          @media #{$lg} {
            @include fontsize(12);
          }
          @media #{$xl} {
            @include fontsize(12);
          }
        }
      }
    }
    .jobStrong {
      line-height: 150%;
      margin: 30px 0 20px;
      p {
        @include fontsize(12);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(13);
        }
        @media #{$lg} {
          @include fontsize(13);
        }
        @media #{$xl} {
          @include fontsize(13);
        }
      }
    }
    a {
      &.interviewLink {
        .jobInterview {
          color: #FFF;
          background-color: #000;
          border: 3px solid #145865;
          @include fontsize(12);
          margin: 0 auto 30px auto;
          width: 60%;
          position:relative;
          @media #{$sm} {
          }
          @media #{$md} {
            width: 100%;
          }
          @media #{$lg} {
            width: 100%;
          }
          @media #{$xl} {
            width: 100%;
          }
          img {
            &.userImage {
              transition-duration: 0.3s;
              width: 100%;
            }
          }
          .jobInterviewInner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: none;

            padding: 30px 20px 0 20px;
            @media #{$xs} {
              padding: 40px 30px 0 30px;
            }
            @media #{$sm} {
            }
            @media #{$md} {
              padding: 70px 50px 0 50px;
            }
            @media #{$lg} {
              padding: 70px 50px 0 50px;
            }
            @media #{$xl} {
              padding: 70px 50px 0 50px;
            }

            h5 {
              margin: 0 0 10px 0;
            }
            p {
              &.position {
                margin: 0;
              }
              &.profile {
                @include fontsize(10);
                margin: 0 0 0 0;
                @media #{$xs} {
                }
                @media #{$sm} {
                }
                @media #{$md} {
                  @include fontsize(13);
                  margin: 0 0 10px 0;
                }
                @media #{$lg} {
                  @include fontsize(13);
                  margin: 0 0 10px 0;
                }
                @media #{$xl} {
                  @include fontsize(13);
                  margin: 0 0 10px 0;
                }
              }
            }
            img {
              display: block;
              width: 80px;
              margin: 20px auto 0;
              @media #{$xs} {
                margin: 40px auto 0;
              }
              @media #{$sm} {
              }
              @media #{$md} {
                width: 100px;
                margin: 80px auto 0;
              }
              @media #{$lg} {
                width: 100px;
                margin: 80px auto 0;
              }
              @media #{$xl} {
                width: 100px;
                margin: 80px auto 0;
              }
            }
          }
        }
        &:hover {
          img {
            &.userImage {
              transition-duration: 0.3s;
              opacity: 0.4;
            }
          }
          .jobInterviewInner {
            display: block;
          }
        }
      }
    }
    .jobListBlock {
      margin: 20px 0 50px;
      h3 {
        border-bottom: 3px solid #145865;
        span {
          display: inline-block;
          background-color: #145865;
          color: #FFF;
          padding: 10px 50px 6px;
          @include fontsize(18);
          @media #{$sm} {
          }
          @media #{$md} {
            @include fontsize(20);
          }
          @media #{$lg} {
            @include fontsize(20);
          }
          @media #{$xl} {
            @include fontsize(20);
          }
        }
      }
      .jobContentBlock {
        margin: 30px 0 0;
        background-color: #f4f4f4;
        position: relative;
        padding: 20px 20px;
        @media #{$sm} {
        }
        @media #{$md} {
          padding: 20px 30px;
        }
        @media #{$lg} {
          padding: 20px 30px;
        }
        @media #{$xl} {
          padding: 20px 35px;
        }
        h4 {
          color: #607899;
          @include fontsize(15);
          @media #{$sm} {
          }
          @media #{$md} {
            @include fontsize(16);
          }
          @media #{$lg} {
            @include fontsize(16);
          }
          @media #{$xl} {
            @include fontsize(16);
          }
        }
        p {
          color: #000;
          margin-bottom: 1.5rem;
          @include fontsize(10);
          @media #{$sm} {
          }
          @media #{$md} {
            @include fontsize(12);
          }
          @media #{$lg} {
            @include fontsize(12);
          }
          @media #{$xl} {
            @include fontsize(12);
          }
        }
        a {
          &.readMore {
            position: absolute;
            bottom: 0;
            right: 0;
            img {
              width: 100px;
              @media #{$sm} {
              }
              @media #{$md} {
                width: 120px;
              }
              @media #{$lg} {
                width: 120px;
              }
              @media #{$xl} {
                width: 120px;
              }
            }
          }
        }
      }
    }


    //送信完了ページ
    .thanksContent {
      margin: 30px 0 60px;
      @media #{$sm} {
      }
      @media #{$md} {
        margin: 50px 0 100px;
      }
      @media #{$lg} {
        margin: 50px 0 100px;
      }
      @media #{$xl} {
        margin: 50px 0 100px;
      }
      p {
        line-height: 200%;
        margin: 25px 0;
        @include fontsize(13);
        @media #{$sm} {
        }
        @media #{$md} {
          @include fontsize(15);
        }
        @media #{$lg} {
          @include fontsize(15);
        }
        @media #{$xl} {
          @include fontsize(16);
        }
      }
    }

    &.bgSpGray {
      background-color: #f4f4f4;
      @media #{$sm} {
      }
      @media #{$md} {
        background-color: #FFF;
      }
      @media #{$lg} {
        background-color: #FFF;
      }
      @media #{$xl} {
        background-color: #FFF;
      }
    }


    //エントリーフォーム
    p {
      &.entryRead {
        @include fontsize(12);
      }
    }

    .entryForm {
      margin: 10px 0 50px;
      dl {
        @media #{$sm} {
        }
        @media #{$md} {
          display: flex;
          flex-wrap: wrap;
        }
        @media #{$lg} {
          display: flex;
          flex-wrap: wrap;
        }
        @media #{$xl} {
          display: flex;
          flex-wrap: wrap;
        }

        dt {
          box-sizing: border-box;
          width: 100%;
          font-weight: normal;
          padding: 20px 0 5px;
          @include fontsize(12);
          @media #{$sm} {
          }
          @media #{$md} {
            border-bottom: 1px solid #CCC;
            padding: 20px 0 20px;
            @include fontsize(14);
            width: 30%;
          }
          @media #{$lg} {
            border-bottom: 1px solid #CCC;
            padding: 20px 0 20px;
            @include fontsize(14);
            width: 30%;
          }
          @media #{$xl} {
            border-bottom: 1px solid #CCC;
            padding: 20px 0 20px;
            @include fontsize(14);
            width: 30%;
          }
          span {
            display: inline-block;
            padding: 4px 15px 3px;
            margin: 0 20px 0 0;
            color: #FFF;
            &.required {
              background-color: #f7931e;
            }
            &.unRequired {
              background-color: #b3b3b3;
            }
          }
        }
        dd {
          border-bottom: 1px solid #CCC;
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          padding: 3px 0 20px;
          @include fontsize(12);
          @media #{$sm} {
          }
          @media #{$md} {
            width: 70%;
            padding: 20px 0 20px;
            @include fontsize(14);
          }
          @media #{$lg} {
            width: 70%;
            padding: 20px 0 20px;
            @include fontsize(14);
          }
          @media #{$xl} {
            width: 70%;
            padding: 20px 0 20px;
            @include fontsize(14);
          }
          li {
            margin: 0 0 10px;
          }

        }

      }

      .inputText {
        padding: 3px 10px;
        @include border-radius(5px);
        border:1px solid #f2f2f2;
        background-color: #f2f2f2;
      }
      .inputWidthFull {
        width: 100%;
      }
      .inputWidthBirthday {
        width: 60px;
      }
      textarea {
        &.motiveTextarea {
          padding: 3px 10px;
          @include border-radius(5px);
          border:1px solid #f2f2f2;
          background-color: #f2f2f2;
          width: 100%;
          height: 200px;
        }
      }

      p {
        @include fontsize(12);
        margin: 5px 0;
      }
      .entryAgree {
        text-align: center;
        @include fontsize(13);
        a {
          text-decoration: underline;
        }
      }
    }
  }



}



p {
  &.topRead {
    margin: 20px 0 30px;
    @include fontsize(12);
    @media #{$sm} {
    }
    @media #{$md} {
      @include fontsize(14);
    }
    @media #{$lg} {
      @include fontsize(14);
    }
    @media #{$xl} {
      @include fontsize(14);
    }
  }

  &.read1 {
    @include fontsize(11);
    @media #{$sm} {
    }
    @media #{$md} {
      @include fontsize(14);
    }
    @media #{$lg} {
      @include fontsize(14);
    }
    @media #{$xl} {
      @include fontsize(14);
    }
  }
  &.read2 {
    line-height: 170%;
    @include fontsize(11);
    @media #{$sm} {
    }
    @media #{$md} {
      @include fontsize(14);
    }
    @media #{$lg} {
      @include fontsize(14);
    }
    @media #{$xl} {
      @include fontsize(14);
    }
  }
  &.pcCenter {
    @media #{$sm} {
    }
    @media #{$md} {
      text-align: center;
    }
    @media #{$lg} {
      text-align: center;
    }
    @media #{$xl} {
      text-align: center;
    }
  }
}

